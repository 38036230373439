import Container from '@/components/Container';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import AloneTab from '@/page-blocks/involve/individual/memoryTab';
import TogetherTab from '@/page-blocks/involve/individual/togetherTab';
import { PageProps, navigate } from 'gatsby';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

const tab: { [key: number]: ReactElement } = {
  0: <AloneTab />,
  1: <TogetherTab />,
};

const tab2 = [
  {
    idx: 0,
    title: `혼자서 기념기부 <em>CLICK</em>`,
    link: `/involve/individual/memory`,
  },
  {
    idx: 1,
    title: `같이 하는 기념기부 <em>CLICK</em>`,
    link: `/involve/individual/together`,
  },
];

const PanelContainer = styled.div`
  height: 80px;
  border-bottom: 3px solid #c6c6c6;

  .UiTabPanel {
    & > li {
      width: 50%;
      text-align: center;
    }
  }
`;
const TabButton = styled.li<{ active: boolean }>`
  cursor: pointer;
  width: 50%;
  text-align: center;
  display: block;
  font-weight: 700;
  line-height: 80px;
  font-size: 20px;
  height: 80px;
  border-bottom: 3px solid #c6c6c6;
  border-color: ${(props) => (props.active ? `#1cabe2` : `#c6c6c6`)};
  color: ${(props) => (props.active ? `#1cabe2` : `#c6c6c6`)};
  transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  margin: 0;
  &:hover {
    border-color: #1cabe2;
    color: #1cabe2;
  }
  em {
    font-weight: 700;
  }
  ${breakpoint(`mobile`)} {
    font-size: 16px;
    // padding: 20px 40px 20px 0;
    display: table;
    line-height: initial;
    span {
      display: table-cell;
      vertical-align: middle;
      font-size: 20px;
      em {
        font-size: 15px;
        display: block;
      }
    }
  }
`;
const TabContainer = styled.div``;
const SectionPanel = styled.section``;

const Memory: React.FC<PageProps> = ({ location }) => {
  const path = useMemo(() => location.pathname, [location]);
  const targetRef = useRef<HTMLLIElement | null>(null);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabMenu = (id: number, link: string) => {
    navigate(link);
    setActiveTab(id);
  };

  // together 탭을 기본으로 설정시
  /*
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  if (queryParams.get('tab') === 'together') {
    navigate(tab2[1].link);
    return <></>;
  }
  */

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="기념기부"
      description="for every child, a special day"
    >
      <SectionPanel>
        <PanelContainer>
          <Container>
            <ul className="UiTabPanel flex">
              {tab2.map(({ title, link, idx }) => (
                <TabButton
                  ref={(ref) => {
                    if (path === link || path === `${link}/`) {
                      targetRef.current = ref;
                    }
                  }}
                  active={path === link || path === `${link}/`}
                  key={link}
                  onClick={() => handleTabMenu(idx, link)}
                >
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </TabButton>
              ))}
            </ul>
          </Container>
        </PanelContainer>
        <TabContainer>{tab[activeTab]}</TabContainer>
      </SectionPanel>
    </LayoutWithTitle>
  );
};
export default Memory;
